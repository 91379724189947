<template>
  <Title pageTitle="Q&amp;A" pageDiscrption="よくある質問" />
  <div class="container">
    <p class="lead">当店ご利用の際にご不明な点などございましたら下記をご参考ください。</p>
    <div class="faq-grid">
      <div v-for="faq in faqs" :key="faq">
        <div class="faq">
          <div class="faq__tag">{{ faq.tag }}</div>
          <div class="faq__title">{{ faq.title }}</div>
          <p>{{ faq.body }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/runtime-core'
import Title from '../components/Title.vue'
export default {
  components: { Title },
  setup() {
    const preData = ref([])
    const faqs = ref([])
    const error = ref(null)

    const load = async () => {
      try {
        let data = await fetch('./data/faq.json')
        if (!data.ok) {
          throw Error('no data available')
        }
        preData.value = await data.json()
        faqs.value = preData.value.faqs
        console.log(faqs.value)
      } catch (err) {
        error.value = err.message
        console.log(error.value)
      }
    }

    load()

    return { faqs }
  },
}
</script>

<style lang="scss" scoped>
.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  align-items: stretch;
  grid-gap: 3rem;
}

.faq {
  border: 1px solid #777;
  background-color: #222;
  padding: 2rem 2rem 1rem;
  border-radius: 10px;
  height: 100%;

  &__tag {
    font-size: 1rem;
    background-color: var(--color-tailor-dark);
    padding: 1px 10px 2px;
    border-radius: 5px;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: 400;
  }

  &__title {
    font-size: 1.6rem;
    font-weight: 300;
    margin-bottom: 2rem;
  }
}
</style>
